import "./index.scss";

/**
 * Import modules
 */

import Inputmask from "inputmask/dist/inputmask.min.js";
import { Fancybox } from "@fancyapps/ui";
import Swiper from "swiper/bundle";
import ymaps from "ymaps";
import lozad from "lozad";
import Toastify from "toastify-js";
import "waypoints/lib/noframework.waypoints.min.js";

/**
 * Helpers
 */
//console.log("null");
const cyrValue = (str) => {
  return /[^а-яА-ЯёЁ -]/gi.test(str);
};
const fancyboxShow = (src, type) => {
  Fancybox.show([
    {
      src: src,
      type: type,
      autoFocus: false,
      trapFocus: false,
      placeFocusBack: false,
    },
  ]);
};
const toast = (title = "", text = "", type = "info") => {
  let message, messageTitle, messageText;
  message = document.createElement("div");
  messageTitle = document.createElement("p");
  messageTitle.classList.add("title");
  messageTitle.innerHTML = title;
  messageText = document.createElement("p");
  messageText.classList.add("text");
  messageText.innerHTML = text;
  message.append(messageTitle, messageText);

  Toastify({
    className: type,
    close: true,
    destination: false,
    duration: 3000,
    escapeMarkup: false,
    gravity: "bottom",
    position: "right",
    stopOnFocus: true,
    text: message.innerHTML,
    onClick: () => {},
  }).showToast();
};

/**
 * Fields validation
 */
if (document.querySelector("input[name='agreement']")) {
  document.querySelectorAll("input[name='agreement']").forEach((el) => {
    el.addEventListener("change", (evt) => {
      const status = evt.currentTarget.checked;

      if (status) {
        evt.currentTarget
          .closest("form")
          .querySelector("button")
          .removeAttribute("disabled");
      } else {
        evt.target
          .closest("form")
          .querySelector("button")
          .setAttribute("disabled", "");
      }
    });
  });
}
if (document.querySelectorAll("input[name='name']")) {
  document.querySelectorAll("input[name='name']").forEach((el) => {
    let counter = 0;

    el.addEventListener("keyup", (evt) => {
      if (cyrValue(evt.target.value)) {
        counter++;

        if (counter > 5) {
          toast("Внимание!", "Имя должно быть на русском языке", "warning");
          counter = 0;
        }
      }

      evt.target.value = evt.target.value.replace(/[^а-яА-ЯёЁ -]/gi, "");
    });
  });
}
if (document.querySelector("input[name='phone']")) {
  document.querySelectorAll("input[name='phone']").forEach((el) => {
    let im = new Inputmask({
      mask: "+7 (e99) 999-99-99",
      definitions: {
        e: {
          validator: "[0-7,9]",
        },
      },
    });
    im.mask(el);

    el.addEventListener("blur", (evt) => {
      if (
        evt.currentTarget.value[17] == "_" ||
        evt.currentTarget.value[17] == undefined
      ) {
        toast("Внимание!", "Некорректный номер", "WARNING");
        evt.target.parentNode.classList.add("error");
        evt.target.parentNode.classList.remove("valid");
      } else {
        evt.target.parentNode.classList.add("valid");
        evt.target.parentNode.classList.remove("error");
      }
    });
  });
}
if (document.querySelector("input[required]")) {
  document.querySelectorAll("input[required]").forEach((el) => {
    el.addEventListener("blur", (evt) => {
      if (evt.target.value.length == 0) {
        toast("Внимание!", "Поле обязательно для заполнения", "WARNING");
      }
    });
  });
}
if (
  document.querySelector("input[type='text'], input[type='email'], textarea")
) {
  document
    .querySelectorAll("input[type='text'], input[type='email'], textarea")
    .forEach((el) => {
      el.addEventListener("blur", (evt) => {
        if (evt.target.value.length > 0) {
          evt.target.parentNode.classList.add("valid");
        } else {
          evt.target.parentNode.classList.remove("valid");
        }
      });
    });
}

var url_string = window.location.href;
var url = new URL(url_string);
var c = url.searchParams.get("utm_medium");
localStorage.setItem("utm_medium", c);
if (c || localStorage.getItem("utm_medium") == "cpc") {
  if (document.querySelector(".numb_phone")) {
    document.querySelectorAll(".numb_phone").forEach((el) => {
      el.style.display = "none";
    });
  }
}
// console.log(localStorage.getItem("utm_medium"));

if (document.querySelector("input[name='file_attach[]']")) {
  document.querySelectorAll("input[name='file_attach[]']").forEach((el) => {
    el.addEventListener("change", (evt) => {
      let totalSize = 0;
      Object.keys(e.currentTarget.files).map((objectKey, index) => {
        totalSize = totalSize + evt.currentTarget.files[index].size;
      });
      if (Math.round((totalSize / 1048576) * 100) / 100 > 10) {
        el.value = "";
        toast("Внимание!", "Максимальный объём вложений - 10 мб.", "WARNING");
      } else if (evt.currentTarget.files.length > 10) {
        el.value = "";
        toast(
          "Внимание!",
          "Максимальное количество вложений - 10 шт.",
          "WARNING"
        );
      } else {
        evt.currentTarget
          .closest("form")
          .querySelector("#file-name").innerText =
          evt.currentTarget.files.length +
          " шт. (" +
          Math.round((totalSize / 1048576) * 100) / 100 +
          " мб.)";
        evt.currentTarget
          .closest("form")
          .querySelector("p.filename")
          .classList.add("is-loaded");
      }
    });
    el.closest("form")
      .querySelector("#file-clear")
      .addEventListener("click", (evt) => {
        el.value = "";
        evt.currentTarget
          .closest("form")
          .querySelector("p.filename")
          .classList.remove("is-loaded");
        document.querySelector("#file-name").innerText = "";
      });
  });
}

/**
 * Form
 */
if (document.querySelector("form.fetch")) {
  document.querySelectorAll("form.fetch").forEach((form) => {
    form.addEventListener("submit", (evt) => {
      evt.preventDefault();
      //let yaGoal = form.querySelector("input[name='ya_goal']").value;

      let phone = form.querySelector("input[name='phone']").value;

      if (phone.length[17] === "_" || phone.length === 0) {
        document.querySelector("input[name='phone']").classList.add("is-error");
      } else {
        fancyboxShow("#loading", "inline");
        if (form.querySelector("input[type='submit']"))
          form
            .querySelector("input[type='submit']")
            .setAttribute("disabled", "");
        if (form.querySelector("button[type='submit']"))
          form
            .querySelector("button[type='submit']")
            .setAttribute("disabled", "");

        let data = new FormData(form);
        leadgets("lead", data, (r) => {
          if (r.status === 1) {
            Fancybox.close();

            if (form.querySelector("input[type='submit']"))
              form
                .querySelector("input[type='submit']")
                .removeAttribute("disabled", "");
            if (form.querySelector("button[type='submit']"))
              form
                .querySelector("button[type='submit']")
                .removeAttribute("disabled", "");

            form.querySelectorAll("input").forEach((inp) => {
              if (
                inp.type !== "submit" &&
                inp.type !== "checkbox" &&
                inp.type !== "radio" &&
                inp.type !== "hidden"
              ) {
                inp.value = "";
                inp.parentNode.classList.remove("valid");
              }
            });
            if (form.querySelector("textarea")) {
              form.querySelector("textarea").value = "";
              form
                .querySelector("textarea")
                .parentNode.classList.remove("valid");
            }
            if (form.querySelector("input[type='checkbox']")) {
              form.querySelectorAll("input[type='checkbox']").forEach((el) => {
                if (el.name != "agreement") el.checked = false;
              });
            }

            setTimeout(() => {
              fancyboxShow("#thanks", "inline");
            }, 150);
            setTimeout(() => {
              Fancybox.close();
            }, 5000);

            if (typeof ym == "function") {
              //ym("XXXXXXXX", "reachGoal", yaGoal);
              //console.log("Цель достигнута: " + yaGoal);
            }
            if (typeof gtag == "function") {
              //gtag("event", "form_lead", {"event_category": "lead", "event_action": "zayavka"});
            }
          } else {
            toast("Внимание!", "Ошибка ! " + r.message, "WARNING");

            Fancybox.close();
            form
              .querySelector("input[type='submit']")
              .removeAttribute("disabled", "");
          }
        });
      }
    });
  });
}

/**
 * Mobile menu
 */
if (document.querySelector("button.hamburger")) {
  document
    .querySelector("button.hamburger")
    .addEventListener("click", (evt) => {
      let buttonClasslist = evt.currentTarget.classList;

      buttonClasslist.toggle("active");
      if (buttonClasslist.contains("active")) {
        document.querySelector(".header__mobile").classList.add("active");
        document.querySelector(".header__mask").classList.add("active");
      } else {
        document.querySelector(".header__mobile").classList.remove("active");
        document.querySelector(".header__mask").classList.remove("active");
      }
    });
  document.querySelector(".header__mask").addEventListener("click", () => {
    document.querySelector("button.hamburger").classList.remove("active");
    document.querySelector(".header__mobile").classList.remove("active");
    document.querySelector(".header__mask").classList.remove("active");
  });
}
if (document.querySelector(".header__mobile .menu-item-has-children")) {
  document
    .querySelectorAll(".header__mobile .menu-item-has-children")
    .forEach((el) => {
      el.querySelector("a").addEventListener("click", (evt) =>
        evt.preventDefault()
      );

      el.addEventListener("click", (evt) => {
        el.classList.toggle("active");
      });
    });
}

/**
 * Modal
 */
if (document.querySelector(".modal-link")) {
  document.querySelectorAll(".modal-link").forEach((el) => {
    el.addEventListener("click", (evt) => {
      evt.preventDefault();

      const button = evt.currentTarget,
        target = button.hash || button.dataset.href,
        popup = document.querySelector(target),
        title = button.dataset.modalTitle,
        content = button.dataset.modalContent,
        footer = button.dataset.modalFooter,
        submit = button.dataset.modalSubmit,
        yagoal = button.dataset.modalYagoal,
        email = button.dataset.modalEmail;

      if (title) popup.querySelector(".modal__title span").innerHTML = title;
      if (submit) popup.querySelector("input[type='submit']").value = submit;
      if (yagoal) popup.querySelector("input[name='ya_goal']").value = yagoal;
      if (email) popup.querySelector("input[name='email_title']").value = email;
      if (target) fancyboxShow(target, "inline");
    });
  });
}

/*MORE SERVICE PRICE*/

if (document.querySelector(".load_more")) {
  document.querySelectorAll(".load_more").forEach((el) => {
    el.addEventListener("click", (evt) => {
      let current_count =
          document.querySelector(".load_more").dataset.activeRows,
        numb_rows = document.querySelectorAll(".accordion__table__row").length;

      document
        .querySelectorAll(".accordion__table__row")
        .forEach(function (item, i) {
          if (i > current_count - 1 && i < +current_count + 30) {
            item.classList.add("is-active");
          }
        });

      current_count = +current_count + 30;
      document
        .querySelector(".load_more")
        .setAttribute("data-active-rows", current_count);

      if (current_count >= numb_rows) {
        document.querySelector(".load_more").classList.add("is-hidden");
      }
    });
  });
}

/**
 * Lazy load
 */
const observer = lozad();
observer.observe();

/**SLIDERS**/

var swiper = new Swiper(".videoSlider", {
  navigation: {
    nextEl: ".videoSlider .swiper-button-next",
    prevEl: ".videoSlider .swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination-video",
    clickable: true,
  },
});

var swiper2 = new Swiper(".gallerySlider", {
  slidesPerView: "auto",
  spaceBetween: 30,
  navigation: {
    nextEl: ".swiper-button-next-gallery",
    prevEl: ".swiper-button-prev-gallery",
  },
  pagination: {
    el: ".swiper-pagination.swiper-pagination-gallery",
    clickable: true,
  },
});

var swiper3 = new Swiper(".cerificatesSlider", {
  slidesPerView: "auto",
  spaceBetween: 30,
  navigation: {
    nextEl: ".swiper-button-next-certificates",
    prevEl: ".swiper-button-prev-certificates",
  },
  pagination: {
    el: ".cerificatesSlider .swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    991: {
      slidesPerView: 4,
    },
  },
});

/**ACCORDION**/

let acc = document.getElementsByClassName("accordion__headered");
let i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function () {
    this.classList.toggle("active");
    let panel = this.nextElementSibling;
    let row = panel.querySelectorAll(".accordion__table__row");
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
      let count = 0;
      setInterval(function () {
        if (count < row.length) {
          row[count].classList.remove("active");
          count++;
        } else {
          return;
        }
      }, 0);
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
      let count = 0;
      setInterval(function () {
        if (count < row.length) {
          row[count].classList.add("active");
          count++;
        } else {
          return;
        }
      }, 100);
    }
  });
}

/**
 * Animation
 */

/*let waypoint1 = new Waypoint({
    element: document.getElementById('work_section'),
    handler: function (direction) {
      if (direction == 'down') {
        let work_items = document.querySelectorAll('.main__work__item');
        let c = 0;
        let timer_work = setInterval(function () {
          work_items[c].classList.add('animate');
          c++;

          if (c === work_items.length) {
            clearInterval(timer_work);
          }
        }, 500);
      }
    },
    offset: '60%'
  });*/

if (document.querySelector(".main__services__item")) {
  document.querySelectorAll(".main__services__item").forEach((el) => {
    let waypoint = new Waypoint({
      element: el,
      handler: function (direction) {
        if (direction == "down") {
          el.classList.add("animate");
        }
      },
      offset: "90%",
    });
  });
}

if (document.querySelector(".specialist__list__item")) {
  document.querySelectorAll(".specialist__list__item").forEach((el) => {
    let waypoint = new Waypoint({
      element: el,
      handler: function (direction) {
        if (direction == "down") {
          el.classList.add("animate");
        }
      },
      offset: "90%",
    });
  });
}

if (document.querySelector(".main__services__more")) {
  let waypoint_more = new Waypoint({
    element: document.querySelector(".main__services__more"),
    handler: function (direction) {
      this.element.classList.add("animate");
    },
    offset: "90%",
  });
}

if (document.querySelector(".main__gallery__wrap__main")) {
  let waypoint_more = new Waypoint({
    element: document.querySelector(".main__gallery__wrap__main"),
    handler: function (direction) {
      this.element.classList.add("animate");
    },
    offset: "80%",
  });
}

if (document.querySelector(".main__banner__content__company")) {
  let waypoint1 = new Waypoint({
    element: document.querySelector(".main__banner__content__company"),
    handler: function (direction) {
      this.element.classList.add("animate");
    },
    offset: "80%",
  });
}

if (document.querySelector(".main__banner__content__company")) {
  let waypoint2 = new Waypoint({
    element: document.querySelector(".main__banner__content__company"),
    handler: function (direction) {
      let banner_advantages = document.querySelectorAll(
        ".main__banner__content__advantages__item"
      );
      let c = 0;
      let inteval_banner_advantages = setInterval(function () {
        banner_advantages[c].classList.add("animate");
        c++;
        if (c === banner_advantages.length) {
          clearInterval(inteval_banner_advantages);
        }
      }, 400);
    },
    offset: "70%",
  });
}

if (document.querySelector(".main__services__sub-services")) {
  let sub_services_waypoint = new Waypoint({
    element: document.querySelector(".main__services__sub-services"),
    handler: function (direction) {
      let sub_services_elements = document.querySelectorAll(
        ".main__services__sub-services__item"
      );
      let c = 0;
      let interval_sub_services_elements = setInterval(function () {
        sub_services_elements[c].classList.add("animate");
        c++;
        if (c === sub_services_elements.length) {
          clearInterval(interval_sub_services_elements);
        }
      }, 150);
    },
    offset: "90%",
  });
}

if (document.querySelector(".main__services-prices")) {
  let services_prices_waypoint = new Waypoint({
    element: document.querySelector(".main__services-prices"),
    handler: function (direction) {
      let services_prices_elements = document.querySelectorAll(
        ".main__services-prices__content__element"
      );
      let c = 0;
      let interval_services_prices_elements = setInterval(function () {
        services_prices_elements[c].classList.add("animate");
        c++;
        if (c === services_prices_elements.length) {
          clearInterval(interval_services_prices_elements);
        }
      }, 100);
    },
    offset: "90%",
  });
}

if (document.querySelector(".specialist__education__list")) {
  let waypoint2 = new Waypoint({
    element: document.querySelector(".specialist__education__list"),
    handler: function (direction) {
      let banner_advantages = document.querySelectorAll(
        ".specialist__education__list__item"
      );
      let c = 0;
      let inteval_banner_advantages = setInterval(function () {
        banner_advantages[c].classList.add("animate");
        c++;
        if (c === banner_advantages.length) {
          clearInterval(inteval_banner_advantages);
        }
      }, 400);
    },
    offset: "70%",
  });
}

if (document.querySelector(".main__why")) {
  let waypoint3 = new Waypoint({
    element: document.querySelector(".main__why"),
    handler: function (direction) {
      let banner_advantages = document.querySelectorAll(".main__why__item");
      let c = 0;
      let inteval_banner_advantages = setInterval(function () {
        banner_advantages[c].classList.add("animate");
        c++;
        if (c === banner_advantages.length) {
          clearInterval(inteval_banner_advantages);
        }
      }, 200);
    },
    offset: "70%",
  });
}

if (document.querySelector(".main__app")) {
  let waypoint4 = new Waypoint({
    element: document.querySelector(".main__app"),
    handler: function (direction) {
      document
        .querySelector(".main__app__banner__picture")
        .classList.add("animate");
      document
        .querySelector(".main__app__banner__apps")
        .classList.add("animate");
    },
    offset: "70%",
  });
}

if (document.querySelector(".btn_humburger")) {
  document.querySelector(".btn_humburger").addEventListener("click", (evt) => {
    let buttonClasslist = evt.currentTarget.classList;

    buttonClasslist.toggle("active");

    if (buttonClasslist.contains("active")) {
      document.querySelector(".header__top__mobile").classList.add("active");
      document.querySelector("body").style.overflow = "hidden";
      document.querySelector("body").style.height = "100%";
      document.querySelector("html").style.overflow = "hidden";
      document.querySelector("html").style.height = "100%";
    } else {
      document.querySelector(".header__top__mobile").classList.remove("active");
      document.querySelector("body").style.overflow = "auto";
      document.querySelector("body").style.height = "auto";
      document.querySelector("html").style.overflow = "auto";
      document.querySelector("html").style.height = "auto";
    }
  });
}

/**
 * Ymaps
 */
// if (document.querySelector("#contacts-map")) {
//   const maps_observer = lozad(".map-lazy", {
//     loaded: (el) => {
//       const coords = el.dataset.coords.split(", "),
//         apiKey = el.dataset.key,
//         icon = el.dataset.icon,
//         label = el.dataset.label,
//         clientWidth = document.body.clientWidth;

//       let center = coords;
//       if (clientWidth > 1199) {
//         center = [Number(coords[0]), Number(coords[1]) - 0.003];
//       } else if (clientWidth < 992) {
//         center = [Number(coords[0]) - 0.0001, Number(coords[1])];
//       }

//       ymaps
//         .load("https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=" + apiKey)
//         .then((maps) => {
//           const myMap = new maps.Map("contacts-map", {
//               center: center,
//               zoom: 17,
//               controls: ["fullscreenControl", "zoomControl"],
//             }),
//             myPlacemark = new maps.Placemark(
//               coords,
//               {
//                 hintContent: label,
//                 balloonContent: label,
//               },
//               {
//                 iconLayout: "default#image",
//                 iconImageHref: icon,
//                 iconImageSize: [100, 100],
//                 iconImageOffset: [-25, -56],
//               }
//             );
//           myMap.behaviors.disable("scrollZoom");
//           myMap.geoObjects.add(myPlacemark);
//         });
//     },
//   });
//   maps_observer.observe();
// }
if (document.querySelector("#contacts-map")) {
  const maps_observer = lozad(".map-lazy", {
    loaded: (el) => {
      const coords = el.dataset.coords.split(", "),
        coordsSecond = el.dataset.coordsSecond.split(", "),
        apiKey = el.dataset.key,
        icon = el.dataset.icon,
        label = el.dataset.label,
        labelSecond = el.dataset.labelSecond, // Read the second label
        clientWidth = document.body.clientWidth;

      let center = coords;
      if (clientWidth > 1199) {
        center = [Number(coords[0]), Number(coords[1]) - 0.003];
      } else if (clientWidth < 992) {
        center = [Number(coords[0]) - 0.0001, Number(coords[1])];
      }

      ymaps
        .load("https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=" + apiKey)
        .then((maps) => {
          const myMap = new maps.Map("contacts-map", {
              center: center,
              zoom: 13,
              controls: ["fullscreenControl", "zoomControl"],
            }),
            myPlacemark = new maps.Placemark(
              coords,
              {
                hintContent: label,
                balloonContent: label,
              },
              {
                iconLayout: "default#image",
                iconImageHref: icon,
                iconImageSize: [100, 100],
                iconImageOffset: [-25, -56],
              }
            ),
            myPlacemarkSecond = new maps.Placemark(
              coordsSecond,
              {
                hintContent: labelSecond, // Use the second label
                balloonContent: labelSecond, // Use the second label
              },
              {
                iconLayout: "default#image",
                iconImageHref: icon,
                iconImageSize: [100, 100],
                iconImageOffset: [-25, -56],
              }
            );
          myMap.behaviors.disable("scrollZoom");
          myMap.geoObjects.add(myPlacemark);
          // myMap.geoObjects.add(myPlacemarkSecond);
        });
    },
  });
  maps_observer.observe();
}

/* mobile menu item toggler */
if (document.querySelector(".header__top__mobile")) {
  const headerTopMobileList = document.querySelector(
    ".header__top__mobile_list"
  );
  headerTopMobileList.addEventListener("click", (e) => {
    if (!e.target.closest("li")) {
      return;
    }
    if (!e.target.closest("li").classList.contains("menu-item")) {
      return;
    }
    if (
      !e.target.closest("li").lastElementChild.classList.contains("dropdown")
    ) {
      return;
    }

    e.target.closest("li").lastElementChild.classList.toggle("active");
  });
}

/* deactivation link functionality */
if (document.querySelector(".header__top__mobile_list")) {
  const mobileHeaderList = document.querySelector(".header__top__mobile_list");
  if (mobileHeaderList.querySelector(".possible-deactivation")) {
    for (const el of mobileHeaderList.querySelectorAll(
      ".possible-deactivation"
    )) {
      el.firstElementChild.style.pointerEvents = "none";
    }
  }
}

if (document.getElementById("scroll-to-top")) {
  const scrollToTopButton = document.getElementById("scroll-to-top");

  const scrollFunc = (heightTrigger = 100) => {
    let y = window.scrollY;

    if (y > heightTrigger) {
      scrollToTopButton.classList.remove("js-is-hidden");
    } else {
      scrollToTopButton.classList.add("js-is-hidden");
    }
  };

  window.addEventListener("scroll", (e) => {
    scrollFunc(500);
  });

  const scrollToTop = (scrollToTopButton, smootherness = 10) => {
    scrollToTopButton.blur();
    const pageScrollTop =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (pageScrollTop > 0) {
      window.requestAnimationFrame(() => {
        scrollToTop(scrollToTopButton, smootherness);
      });
      window.scrollTo(0, pageScrollTop - pageScrollTop / smootherness);
    }
  };

  scrollToTopButton.addEventListener("click", (e) => {
    scrollToTop(e.currentTarget, 20);
  });
}
