import gsap from "gsap";

// Селект
document.addEventListener("DOMContentLoaded", () => {
  const selects = document.querySelectorAll(
    ".feedback__wrapper__controls__select"
  );

  if (selects.length) {
    selects.forEach((container) => {
      const trigger = container.querySelector(
        ".feedback__wrapper__controls__select-trigger"
      );
      const optionsWrapper = container.querySelector(
        ".feedback__wrapper__controls__select-options"
      );
      const options = container.querySelectorAll(
        ".feedback__wrapper__controls__select-option"
      );

      // Анимация с сбросом свойств
      const animation = gsap.fromTo(
        optionsWrapper,
        {
          opacity: 0,
          scaleY: 0.95,
          paused: true,
          duration: 0.3,
          ease: "power1.inOut",
          clearProps: "opacity,transform,display", // Сброс ключевых свойств
        },
        {
          opacity: 1,
          scaleY: 1,
          display: "block",
          paused: true,
        }
      );

      // Обработчик триггера
      trigger.addEventListener("click", (e) => {
        e.stopPropagation();
        const isOpen = trigger.getAttribute("aria-expanded") === "true";

        // Закрываем другие селекты
        document
          .querySelectorAll(".feedback__wrapper__controls__select")
          .forEach((sel) => {
            if (sel !== container) {
              const otherWrapper = sel.querySelector(
                ".feedback__wrapper__controls__select-options"
              );
              gsap.set(otherWrapper, { clearProps: "all" }); // Полный сброс
              otherWrapper.classList.remove("active");
              sel
                .querySelector(".feedback__wrapper__controls__select-trigger")
                .setAttribute("aria-expanded", false);
            }
          });

        // Переключение состояния
        if (!isOpen) {
          animation.play();
          optionsWrapper.classList.add("active");
        } else {
          animation.reverse();
          optionsWrapper.classList.remove("active");
        }

        trigger.setAttribute("aria-expanded", !isOpen);
      });

      // Обработчик выбора опции
      options.forEach((option) => {
        option.addEventListener("click", () => {
          const selectedValue = option.textContent;
          container.querySelector(
            ".feedback__wrapper__controls__select-value"
          ).textContent = selectedValue;
          options.forEach((opt) => opt.classList.remove("selected"));
          option.classList.add("selected");

          // Закрываем селект
          animation.reverse();
          optionsWrapper.classList.remove("active");
          trigger.setAttribute("aria-expanded", false);
        });
      });
    });

    // Закрытие при клике вне
    document.addEventListener("click", (e) => {
      selects.forEach((container) => {
        const optionsWrapper = container.querySelector(
          ".feedback__wrapper__controls__select-options"
        );
        const trigger = container.querySelector(
          ".feedback__wrapper__controls__select-trigger"
        );

        if (
          !container.contains(e.target) &&
          trigger.getAttribute("aria-expanded") === "true"
        ) {
          gsap.to(optionsWrapper, {
            opacity: 0,
            scaleY: 0.95,
            duration: 0.3,
            ease: "power1.inOut",
            clearProps: "opacity,transform,display", // Важный сброс
            onComplete: () => {
              optionsWrapper.classList.remove("active");
              trigger.setAttribute("aria-expanded", false);
            },
          });
        }
      });
    });
  }
});

// Фильтр отзывы
document.addEventListener("DOMContentLoaded", function () {
  const feedbackWrapper = document.querySelector(".feedback__wrapper");
  if (!feedbackWrapper) return;

  let currentPage = 1;

  function initializeFeedbackItems() {
    const feedbackItems = document.querySelectorAll(
      ".feedback__wrapper__list__item"
    );

    feedbackItems.forEach((item) => {
      const reviewText = item.querySelector(
        ".feedback__wrapper__list__item__inner__body__text__review"
      );
      const toggleBtn = item.querySelector(
        ".feedback__wrapper__list__item__inner__body__text__toggle"
      );

      if (!reviewText || !toggleBtn) return;

      const checkHeight = () => {
        const isMobile = window.innerWidth <= 768;
        const maxHeight = isMobile ? 250 : 190;

        // Сброс стилей для точного измерения
        gsap.set(reviewText, { maxHeight: "none" });
        const naturalHeight = reviewText.scrollHeight;

        // Управление видимостью кнопки
        toggleBtn.style.display = naturalHeight > maxHeight ? "flex" : "none";

        if (
          !reviewText.classList.contains(
            "feedback__wrapper__list__item__inner__body__text__review--expanded"
          )
        ) {
          gsap.set(reviewText, {
            maxHeight: naturalHeight > maxHeight ? maxHeight : "none",
          });
        }
      };

      const toggleReview = () => {
        const isExpanded = reviewText.classList.toggle(
          "feedback__wrapper__list__item__inner__body__text__review--expanded"
        );
        const targetHeight = isExpanded
          ? reviewText.scrollHeight
          : window.innerWidth <= 768
          ? 250
          : 190;

        gsap.to(reviewText, {
          maxHeight: targetHeight,
          duration: 0.3,
          ease: "power2.inOut",
          onComplete: () => {
            if (isExpanded) gsap.set(reviewText, { maxHeight: "none" });
          },
        });

        toggleBtn.querySelector("span").textContent = isExpanded
          ? "Свернуть"
          : "Читать полностью";
        toggleBtn.classList.toggle("active");
      };

      // Инициализация при загрузке и ресайзе
      checkHeight();
      toggleBtn.addEventListener("click", toggleReview);
      window.addEventListener("resize", checkHeight);
    });
  }

  // Обработчик выбора опций
  document
    .querySelectorAll(".feedback__wrapper__controls__select")
    .forEach((select) => {
      select.addEventListener("click", (e) => {
        const option = e.target.closest(
          ".feedback__wrapper__controls__select-option"
        );
        if (!option) return;

        const currentSelect = e.currentTarget;
        const isAllOption = option.dataset.id === "all";

        currentSelect
          .querySelectorAll(".selected")
          .forEach((opt) => opt.classList.remove("selected"));
        if (!isAllOption) option.classList.add("selected");

        const trigger = currentSelect.querySelector(
          ".feedback__wrapper__controls__select-value"
        );
        const selectedOption =
          currentSelect.querySelector(".selected") ||
          currentSelect.querySelector('[data-id="all"]');
        if (trigger && selectedOption) {
          trigger.textContent = selectedOption.textContent.trim();
        }

        currentPage = 1;
        fetchFeedback(currentPage);
      });
    });

  // Основная функция фильтрации
  function fetchFeedback(page = 1) {
    const serviceId = getSelectedValue("#service-select");
    const doctorId = getSelectedValue("#doctor-select");

    fetch("/wp-admin/admin-ajax.php", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        action: "filter_feedback",
        doctor_id: doctorId,
        service_id: serviceId,
        page: page,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const list = document.querySelector(".feedback__wrapper__list");
        const pagination = document.getElementById("feedback-pagination");

        if (list) {
          list.innerHTML = data.html || "<p>Отзывов нет</p>";
          initializeFeedbackItems(); // Реинициализация обработчиков
        }

        if (pagination) {
          pagination.innerHTML = data.pagination || "";
          setupPaginationHandlers();
        }
      })
      .catch(() => {
        const list = document.querySelector(".feedback__wrapper__list");
        if (list) list.innerHTML = "<p>Ошибка загрузки отзывов</p>";
      });
  }

  function getSelectedValue(selector) {
    const selected = document.querySelector(`${selector} .selected`);
    return selected?.dataset?.id || "all";
  }

  function setupPaginationHandlers() {
    document
      .querySelectorAll(".pagination__page, .pagination__arrow:not(.disabled)")
      .forEach((btn) => {
        btn.addEventListener("click", (e) => {
          const newPage = parseInt(
            e.target.closest("button")?.dataset.page,
            10
          );
          if (newPage && newPage !== currentPage) {
            currentPage = newPage;
            fetchFeedback(currentPage);
          }
        });
      });
  }

  // Инициализация при загрузке
  initializeFeedbackItems();
  setupPaginationHandlers();
  fetchFeedback(currentPage);
});

// Модальное окно оставить отзыв
document.addEventListener("DOMContentLoaded", function () {
  const popup = document.getElementById("popup__feedback");

  // Рейтинг (звезды)
  const starRating = popup.querySelector(".star-rating");
  if (starRating) {
    const stars = starRating.querySelectorAll(".star");
    const ratingInput = popup.querySelector('input[name="rating"]');

    let currentRating = starRating.dataset.starValue || 5;
    updateStars(currentRating);
    ratingInput.value = currentRating;

    function updateStars(value) {
      stars.forEach((star) => {
        star.style.fill = star.dataset.value <= value ? "#A88867" : "#D9D9D9";
      });
    }

    stars.forEach((star) => {
      star.addEventListener("click", () => {
        currentRating = star.dataset.value;
        starRating.dataset.starValue = currentRating;
        ratingInput.value = currentRating;
        updateStars(currentRating);
      });

      star.addEventListener("mouseover", () => updateStars(star.dataset.value));
      star.addEventListener("mouseout", () => updateStars(currentRating));
    });
  }

  // Выбор услуги/врача
  const selects = popup.querySelectorAll(
    ".feedback__wrapper__controls__select"
  );

  selects.forEach((select) => {
    const selectedValue = select.querySelector(
      ".feedback__wrapper__controls__select-value"
    );
    const hiddenInputName = select.dataset.hiddenInput;
    const hiddenInput = popup.querySelector(`input[name="${hiddenInputName}"]`);
    const options = select.querySelector(
      ".feedback__wrapper__controls__select-options"
    );

    // Инициализация
    const initialOption =
      options.querySelector(".selected") ||
      options.querySelector('[data-id="all"]');
    if (initialOption) {
      const text = getDisplayText(initialOption, hiddenInputName);
      selectedValue.textContent = text;
      hiddenInput.value = initialOption.dataset.id === "all" ? "all" : text;
    }

    // Обработчик клика на опции
    options
      .querySelectorAll(".feedback__wrapper__controls__select-option")
      .forEach((option) => {
        option.addEventListener("click", () => {
          const text = getDisplayText(option, hiddenInputName);
          selectedValue.textContent = text;
          hiddenInput.value = option.dataset.id === "all" ? "all" : text;
        });
      });
  });

  // Вспомогательная функция для отображения текста
  function getDisplayText(option, hiddenInputName) {
    if (option.dataset.id === "all") {
      return hiddenInputName === "service_id" ? "Все услуги" : "Все врачи";
    }
    return option.textContent.trim();
  }
});